<template>
  <div>
    <div v-bind:class="{'container py-3 mt-4': selectedTab != 'history', 'container-fluid py-3 px-3 mt-4': selectedTab == 'history'}">
      <div class="row">
        <div v-bind:class="{'col-3': true, 'pl-4' : selectedTab == 'history'}">
          <div class="row">
            <div class="col bg-white rounded shadow-sm py-3 border" v-bind:class="{'border-warning': newNote.id}">
              <h1 v-if="newNote.id">Notitie bewerken</h1>
              <h1 v-if="!newNote.id">Nieuwe notitie</h1>
              
              <weekPlanNote ref="noteForm" v-on:deleteNote="deleteNote" v-on:cancel="cancelEdit" key="newNote" @submit="submitNote" :lessons="lessons" :students="students" :levels="levels" :note="newNote"/>
            </div>
          </div>
        </div>
        
        <div class="col-9" v-bind:class="{'col-9' : selectedTab != 'historyx', 'col-12' : selectedTab == 'historyx'} ">
          <div class="row">
            <div class="col-10">        
              <div class="nav nav-tabs">
                <div class="nav-item">
                  <button @click="selectTab('all')" class="nav-link" v-bind:class="{'active': selectedTab == 'all'}">Alle notities</button>
                </div>
                <div class="nav-item">
                  <button @click="selectTab('day')" class="nav-link" v-bind:class="{'active': selectedTab == 'day'}">Per dag</button>
                </div>
                <div class="nav-item">
                  <button @click="selectTab('course')" class="nav-link" v-bind:class="{'active': selectedTab == 'course'}">Per vak</button>
                </div>
                <div class="nav-item">
                  <button @click="selectTab('student')" class="nav-link" v-bind:class="{'active': selectedTab == 'student'}">Per leerling</button>
                </div>
                <div class="nav-item">
                  <button @click="selectTab('history')" class="nav-link" v-bind:class="{'active': selectedTab == 'history'}"><i class="fas fa-history"></i> Zoeken & printen</button>
                </div>
              </div>
            </div>
            <div class="col-2 text-right pt-2" v-if="selectedTab != 'historyx'">
              <toggle-button v-model="showDetails" :sync="true" :labels="{checked: 'Details', unchecked: 'Details'}" :fontSize="12" :width="75" />
            </div>
          </div>

      
          <div class="row">
            <div class="col" >
              <div class="bg-white border rounded shadow-sm py-3 px-2" style="border-top-left-radius: 0 !important" >
                <weekPlanNote @editNote="loadNoteInForm" @submit="submitNote" :lessons="lessons" :students="students" :note="note" :key="`note-${note.id}`" :levels="levels" :showDetails="showDetails" v-for="note in notesToShowInList" v-if="selectedTab == 'all' "/>

                <div v-if="notesToShowInList.length == 0 && selectedTab != 'history'">
                  <div class="card text-center">
                    <div class="card-body text-muted">
                      Er zijn nog geen notities voor deze week
                    </div>
                  </div>
                </div>


                <div v-if="notesToShowInList.length > 0 || selectedTab == 'history'">

                  <div v-if="selectedTab == 'day'">
                    <div v-for="day in theNotes.per_day">
                      <h5>{{day.day}}</h5>
                      <div v-if="day.notes.length">
                        <weekPlanNote @editNote="loadNoteInForm" @submit="submitNote" :lessons="lessons" :students="students" :showDetails="showDetails" :note="note" :levels="levels" :key="`note-${note.id}`" v-for="note in day.notes"/>
                      </div>

                      <div v-if="!day.notes.length" class="bg-light text-muted text-center rounded py-2">
                        <em>Er zijn nog geen notities voor {{day.day.toLowerCase() }}</em>
                      </div>
                      
                    </div>
                  </div>

                  <div v-if="selectedTab == 'course'">

                      <div v-if="!theNotes.per_course.length" class="bg-light text-muted text-center rounded py-2">
                        <em>Er zijn nog geen notities die zijn gekoppeld aan een bepaalde les</em>
                      </div>


                    <div v-for="item in theNotes.per_course">
                      <h5>{{item.course}}</h5>
                      <weekPlanNote :showDetails="showDetails" @editNote="loadNoteInForm" @submit="submitNote" :lessons="lessons" :students="students" :levels="levels" :note="note" :key="`note-${note.id}`" v-for="note in item.notes"/>
                    </div>
                  </div>

                  <div v-if="selectedTab == 'student'">
                    <div v-for="item in theNotes.per_student">
                      <h5>{{item.student}}</h5>
                      <weekPlanNote @editNote="loadNoteInForm" @submit="submitNote" :showDetails="showDetails" :hideOtherStudents="true" :levels="levels" :lessons="lessons" :students="students" :note="note" :key="`note-${note.id}`" v-for="note in item.notes"/>
                    </div>
                  </div>

                  <div v-if="selectedTab == 'history'">
                    <weekPlanNoteSearch :week_plans_for_year="week_plans_for_year" @editNote="loadNoteInForm" :showDetails="showDetails" :lessons="lessons" :courses="courses" :students="students" :id="id" :printurl="printurl"/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import weekPlanNoteSearch from './weekPlanNoteSearch.vue'
import weekPlanNote from './weekPlanNote.vue'
import axios from 'axios'

export default {
  props: ["id", "lessons", "students", "notes", "courses", "printurl", "week_plans_for_year", "levels"],
  components: {weekPlanNote, weekPlanNoteSearch},
  data () {
    return {
      newNote: {'lesson_or_week_plan_day_id': null, 'for_all_students': true, student_ids: []},
      theNotes: {},
      selectedTab: 'all',
      showDetails: false
    }
  },
  created(){
    this.theNotes = this.notes
  },
  computed: {
    notesToShowInList(){
      return this.theNotes.all
    }
  },
  methods: {
    loadNoteInForm(noteToEdit){
      // console.log(noteToEdit)
      this.newNote = {student_ids: noteToEdit.student_ids, lesson_or_week_plan_day_id: noteToEdit.lesson_or_week_plan_day_id,  body: noteToEdit.body, lesson_id: noteToEdit.lesson_id, id: noteToEdit.id, for_all_students: noteToEdit.for_all_students, show_task: noteToEdit.show_task, show_goal: noteToEdit.show_goal}
      setTimeout(function () {
        this.$refs.noteForm.setValues()
      }.bind(this), 50)
    },
    cancelEdit(noteToEdit){
      console.log("cancelling edit")
      setTimeout(function () {
        this.newNote = {id: null, 'lesson_id': null, 'for_all_students': true, 'student_ids': []}
        console.log("cancelling")
        console.log(this.newNote)
        this.$refs.noteForm.setValues()
      }.bind(this), 150)
    },
    selectTab(tab){
      this.selectedTab = tab
    },
    deleteNote(note){
      axios.delete(`/notes/${note.id}.json?week_plan_id=${this.id}`)
        .then(response => {
          this.theNotes = response.data
          this.newNote = {'lesson_id': null, 'for_all_students': true}    
          setTimeout(function () {
            this.$refs.noteForm.setValues()
          }.bind(this), 50)
        })
      
    },
    submitNote(note){
      console.log("submitting note")
      console.log(note)
      if (note.id){
        if (note.lesson_id == null){
          this.selectTab('all')
        } 
        axios.put(`/notes/${note.id}.json`, {'note': note, 'week_plan_id': this.id})
        .then(response => {
          this.theNotes = response.data
          this.newNote = {'lesson_id': null, 'lesson_or_week_plan_day_id': null, 'for_all_students': true, student_ids: []}
          setTimeout(function () {
            this.$refs.noteForm.setValues()
          }.bind(this), 50)
        })
      } else {
        // note.student_ids = note.studentIds
        if (note.lesson_id == null){
          this.selectTab('all')
        } 
        axios.post('/notes.json', {'note': note, 'week_plan_id': this.id})
        .then(response => {
          this.theNotes = response.data
          this.newNote = {'lesson_id': null, 'lesson_or_week_plan_day_id': null, 'for_all_students': true, student_ids: []}
          setTimeout(function () {
            this.$refs.noteForm.setValues()
          }.bind(this), 50)
        })
      }
      
    }
  }
}
</script>


<style lang="css" scoped>
h5{
  font-weight: 500;
}
.nav-tabs{
  border: none;
}
.nav-tabs .nav-item{
  margin-right: 10px;
}
.nav-link{
  font-size: 0.9rem;
  font-weight: 600;
}
.nav-link.active{
  /*font-weight: 600;*/
  color: #000;
  position: relative;
  z-index: 700;
}
.nav-link:focus { outline:0 !important; }
</style>