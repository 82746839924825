<template>
  <div class="px-2">
    <div class="row">    
      <div class="col">
        <div class="form-group">
          <label for="students">Leerling</label>
          <select v-model="selectedStudent" class="custom-select" id="students">
            <option :value="null" selected>Alle leerlingen</option>
            <option v-for="student in students" :value="student.id">{{student.name}}</option>
            
          </select>
        </div>  
      </div>
      
      <div class="col">
        <div class="form-group">
          <label for="courses">Vak</label>
          <select v-model="selectedCourse" class="custom-select" id="courses">
            <option :value="null" selected>Alle vakken</option>
            <option v-for="course in courses" :value="course.id">{{course.name}}</option>
          </select>
        </div>  
      </div>

     <!--  <div class="col">
        <div class="form-group">
          <label for="startweek">Van week</label>
          <select v-model="startWeek" class="custom-select" id="startweek">
            <option v-for="week in week_plans_for_year" :value="week">{{week.show_week_number}} ({{week.show_date}})</option>
          </select>
        </div>  
      </div>

      <div class="col">
        <div class="form-group">
          <label for="endweek">Tot week</label>
          <select v-model="endWeek" class="custom-select" id="endweek">
            <option v-for="week in until_week_plans_for_year" :value="week">{{week.show_week_number}} ({{week.show_date}})</option>
          </select>
        </div>  
      </div>
 -->

      <div class="col">
        <div class="form-group">
          <label for="startweek">Van</label>
          <input type="date" v-model="startDate" class="form-control">
            
        </div>  
      </div>

      <div class="col">
        <div class="form-group">
          <label for="endweek">Tot en met</label>
          <input type="date" v-model="endDate" class="form-control">
        </div>  
      </div>

      <div class="col">
        <div class="form-group">
          <label for="query">Zoekwoord</label>
          <input @keyup.enter="search" v-model="query" class="form-control" placeholder="Zoekwoord..." id="query" />
            
        </div>  
      </div>

      <div class="col">
        <div class="form-group">
          <label>&nbsp;</label>
          <button @click="search" class="btn btn-primary btn-block"><span>Zoeken</span>  <i class="fas fa-search"></i></button>
        </div>  
      </div>

    </div>

    <div class="row" v-if="loading">
      <div class="col pt-5 text-center">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
      </div>
    </div>

    <div class="row" v-if="noResults">
      <div class="col pt-5 text-center">
        <em>Geen notities gevonden</em>
      </div>
    </div>

    <div class="row" v-if="results.length">
      <div class="col">
        <table class="table">
          <thead>
            <tr>
              <th>Vak</th>
              <th>Datum</th>
              <th>Notitie</th>
              <th v-if="showDetails">Gebruiker</th>
              <th v-if="showDetails"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="note in results" :key="note.id">
              <td>
                {{ note.show_course_name}}
              </td>
              <td>
                {{ note.show_time}}
              </td>
 
              <td>
                {{note.body}}
                <div v-if="note.students && note.students.length">
                  <span class="badge badge-primary mr-1" v-for="student in note.students">{{ student }}</span>
                </div>
              </td>

              <td v-if="showDetails">{{ note.user_name }}</td>
              <td v-if="showDetails" style="text-align: right;">
                <button class="btn btn-primary btn-sm" @click="toggleEdit(note)">bewerk</button>
              </td>
            </tr>
          </tbody>
        </table> 
      </div>
    </div>
    <div class="row" v-if="results.length">
      <div class="col text-center">
        <button @click="print" v-if="!showPrintDialog" class="btn btn-outline-primary"><i class="fas fa-print"></i> Deze resultaten afdrukken</button>
        <div v-if="showPrintDialog" class="alert alert-info">
          <i class="fas fa-print"></i> Afdrukken gestart! Het PDF document staat bij de <a :href="printurl">printopdrachten</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {

  name: 'weekPlanNoteSearch',
  props: ["students", "lessons", "courses", "id", "printurl", "week_plans_for_year", "showDetails"],
  data () {
    return {
      selectedStudent: null,
      selectedCourse: null,
      showPrintDialog: false,
      loading: false,
      startWeek: null,
      startDate: null,
      endDate: null,
      query: '',
      endWeek: null,
      noResults: false,
      results: []
    }
  },
  created(){
    this.startWeek = this.week_plans_for_year[0]
    this.endWeek = this.week_plans_for_year[this.week_plans_for_year.length-1]

    this.startDate = this.week_plans_for_year[0].start_date
    this.endDate = this.week_plans_for_year[this.week_plans_for_year.length-1].end_date
  },
  computed: {
    until_week_plans_for_year() {
      return this.week_plans_for_year.filter((week_plan) => {
        return (this.startWeek && (week_plan.timestamp > this.startWeek.timestamp))
      })
    }
  },
  methods: {
    toggleEdit(note){
      // this.viewMode = !this.viewMode
      this.$emit('editNote', note)
    },
    print(){
      
      this.showPrintDialog = true
      return axios.post(`/week_plans/${this.id}/print_jobs.json`, {print_job: {template_name: 'log', options: {student_id: this.selectedStudent, course_id: this.selectedCourse, start_date: this.startDate, end_date: this.endDate}}}).
        then(response => {
          console.log(response)
        })
    },
    search() {
      this.showPrintDialog = false
      this.loading    = true
      this.results    = []
      this.noResults  = false
      // return axios.get(`/notes.json?student_id=${this.selectedStudent}&course_id=${this.selectedCourse}&week_plan_id=${this.id}&start_week_id=${this.startWeek.id}&end_week_id=${this.endWeek.id}&q=${this.query}`).
      return axios.get(`/notes.json?student_id=${this.selectedStudent}&course_id=${this.selectedCourse}&week_plan_id=${this.id}&start_date=${this.startDate}&end_date=${this.endDate}&q=${this.query}`).
        then(response => {
          console.log(response)
          this.results = response.data
          this.loading = false
          if (this.results.length){
            this.noResults = false
          } else {
            this.noResults = true
          }
        })
    }
  }
}
</script>

<style lang="css" scoped>
</style>